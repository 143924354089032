<template>
    <Fragment>
        <v-dialog v-model="isOpen" persistent width="1400">
            <div class="upsert-product-modal pb-8 pt-16 px-8 position-relative" style="position: relative;">
                <v-btn @click="close" icon color="secondary" style="position: absolute; top: 16px; left: 16px;">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <div v-if="precios">
                    <h3 class="mb-2">Precio</h3>
                    <v-btn dark color="primary" class="cm-producto-btn flex-grow-1 flex-sm-grow-0 flex-shrink-1 mb-2 "
                        @click="abrirAgregar()">Agregar</v-btn>
                        <div style="max-height: 500px; overflow-y: auto;">
                            <v-data-table :headers="precioHeaders" :items="items" hide-default-footer disable-pagination>
                                <template v-slot:item.proveedor="{ item }">
                                    {{ item?.Proveedor?.nombre_comercial ?? 'Sin proveedor' }}
                                </template>
                                <template v-slot:item.precio="{ item }">
                                    <div class="d-flex justify-center" style="width: 150px;">
                                        <v-text-field 
                                            v-model="item.precio" 
                                            outlined 
                                            dense 
                                            hide-details 
                                            class="text-center"
                                            type="number" 
                                        />
                                    </div>
                                </template>
                                <template v-slot:item.disponibilidad="{ item }">
                                    <div class="d-flex justify-center" style="width: 150px;">
                                        <v-text-field 
                                            v-model="item.disponibilidad"
                                            outlined 
                                            dense 
                                            hide-details 
                                            class="text-center"
                                            type="number"
                                            :disabled="validacion()"
                                        />
                                    </div>
                                </template>
                                <template v-slot:item.marca="{ item }">
                                    <div class="d-flex justify-center" style="width: 150px;">
                                        <v-text-field 
                                            v-model="item.marca"
                                            outlined 
                                            dense 
                                            hide-details 
                                            class="text-center"
                                            type="text"
                                        />
                                    </div>
                                </template>
                                <template v-slot:item.unidad_medida="{ item }">
                                    <div class="d-flex justify-center" style="width: 150px;">
                                    <v-autocomplete
                                        v-model="item.id_unidad_medida"
                                        label="Unidad de medida"
                                        dense
                                        hide-details 
                                        :items="unidad_list.data"
                                        item-text="nombre"
                                        item-value="id"
                                        outlined
                                    />
                                    </div>
                                </template>
                                <template v-slot:item.precio_con_iva="{ item }">
                                    <div class="d-flex align-center">
                                        <v-checkbox
                                        v-model="item.precio_con_iva"
                                        outlined 
                                        dense 
                                        hide-details
                                        color="primary"

                                        ></v-checkbox>
                                            <v-chip
                                            color="primary"
                                            label
                                            outlined
                                        >
                                        {{ !!item.precio_con_iva ? 'CON IVA' : 'SIN IVA' }}
                                        </v-chip>
                                    </div>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <div class="d-flex justify-center">
                                        <v-btn @click="confirmarActualizacionOferta(item)" color="primary white--text"
                                            class="mx-4">Guardar</v-btn>
                                        <v-icon x-small class="ml-2" color="primary" @click.stop="confirmarEliminacionOferta(item)">
                                            mdi-delete
                                        </v-icon>
                                        <v-icon x-small class="ml-2" color="primary" @click.stop="abrirModal(item)">
                                            mdi-plus-thick
                                        </v-icon>
                                        <v-icon x-small class="ml-2" color="primary" @click.stop="abrirModalGastosAdministrativos(item)">
                                            mdi-cash-register
                                        </v-icon>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                    <div class="text-center mt-5">
                        <v-btn class="secondary" @click.stop="close">Cerrar</v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
        <CmOfertaModal 
            :isOpen="modalAgregar" 
            @close="modalAgregar = false" 
            :producto="producto"
            :proveedores="proveedor_list" 
            :unidad="unidad_list"
            @buscarProveedor="buscarProveedor"
            @cargarMasProveedores="cargarMasProveedores"
            @cargarUnidadesMedida="cargarUnidadesMedida"
            @on-save="onSaveOffer" 
        />
        <CmDireccionModal 
            :isOpen="modalDireccion" 
            @close="modalDireccion = false" 
            :oferta="activeOferta"
            :direccion="direccion" 
        />
        <CmRegionModal
            :isOpen="modalRegion" 
            @close="modalRegion = false" 
            :oferta="activeOferta" 
        />
        <cmGastosAdministrativos
            :isOpen="modalCombustible" 
            @close="modalCombustible = false" 
            :oferta="activeOferta" 
            :gastos="listadoConfiguracionGastos"
            :sku="listaSku"
            @buscarSku="buscarSku"
            @cargarMasSku="cargarMasSku"
        />

        <ConfirmationModalComponent 
            :isOpen="modalConfirmacionAbierta" 
            description="¿Desea continuar con la acción?"
            @confirm="actualizarOferta" 
            @cancel="modalConfirmacionAbierta = false" 
            :isLoading="upsertAction.isLoading" 
        />
        <ConfirmationModalComponent 
            :isOpen="modalConfirmacionEliminar" 
            description="¿Desea continuar con la acción?"
            @confirm="eliminarOferta" 
            @cancel="modalConfirmacionEliminar = false" 
            :isLoading="deleteAction.isLoading" 
        />
    </Fragment>
</template>
<script>
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import CmDireccionModal from './CmDireccionModal.vue';
import CmOfertaModal from './CmOfertaModal.vue';
import CmRegionModal from './cmRegionModal.vue';
import CmGastosAdministrativos from './CmGastosAdministrativos.vue'

import { 
    createLoadable, 
    createPageable,
    togglePageable,
    setPageableResponse,
    toggleLoadable,
    setLoadableResponse,
    isResponseSuccesful,
} from '@/utils/loadable.js';
import { Fragment } from 'vue-fragment';
import { mapActions } from "vuex";

export default {
    name: 'CmPrecioModal',
    components: { ConfirmationModalComponent, CmDireccionModal, CmOfertaModal, CmRegionModal, CmGastosAdministrativos },
    data: () => ({
        deleteAction: createLoadable(null),
        upsertAction: createLoadable(null),
        unidad_list: createLoadable([]),
        proveedor_list: createPageable([], 30),
        listaSku: createPageable([], 30),
        filtroProveedor: '',
        filtroSku: '',
        modalDireccion: false,
        modalRegion: false,
        modalAgregar: false,
        modalConfirmacionAbierta: null,
        modalConfirmacionEliminar: null,
        ofertaActiva: null,
        activeOferta:null,
        modalCombustible: false,
        listadoConfiguracionGastos: [],
        precioHeaders: [
            { align: 'center', sortable: false, text: 'Proveedor', value: 'proveedor' },
            { align: 'center', sortable: false, text: 'Precio', value: 'precio' },
            { align: 'center', sortable: false, text: 'Disponibilidad', value: 'disponibilidad' },
            { align: 'center', sortable: false, text: 'Marca', value: 'marca' },
            { align: 'center', sortable: false, text: 'Unidad de medida', value: 'unidad_medida' },
            { align: 'center', sortable: false, text: 'Valor', value: 'precio_con_iva' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'actions' },
        ],
        paginacionProveedores: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        paginacionSku: {
            pagina: 1,
            registrosPorPagina: 30,
        },
    }),
    props: {
        isOpen: { type: Boolean, },
        precios: { type: Array },
        isLoading: { type: Boolean },
        producto: { type: Object },
        direccion: { type: Object}
    },
    emits: ['close', 'on-save'],
    methods: {
        ...mapActions("cmConvenioStore", ["cargarDirecciones","cargarRegiones"]),
        close() {
            this.$emit('close');
        },
        async eliminarOferta() {
            const oferta = this.ofertaActiva;
            toggleLoadable(this.deleteAction);
            const { data } = await this.services.cmProducto.deletePrecioProducto(oferta.id);
            setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            if (isResponseSuccesful(data)) {
                this.$emit('refresh-ofertas', oferta);
            }
            this.modalConfirmacionEliminar = false;
        },
        async actualizarOferta() {
            const oferta = this.ofertaActiva;
            let params = {
                precio: oferta.precio,
                disponibilidad: oferta.disponibilidad,
                id_producto: oferta.id_producto,
                id_convenio_marco: oferta.CMProducto.cmpc?.id_convenio_marco,
                precio_con_iva: oferta.precio_con_iva,
                marca: oferta.marca,
                id_unidad_medida: oferta.id_unidad_medida,
            };
            toggleLoadable(this.upsertAction);
            const { data } = await this.services.cmProducto.actualizarPrecioProducto(oferta.id, params);
            setLoadableResponse(this.upsertAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            if (isResponseSuccesful(data)) {
                this.$emit('refresh-ofertas', oferta);
            }
            this.modalConfirmacionAbierta = false;
        },
        confirmarActualizacionOferta(oferta) {
            this.ofertaActiva = oferta; 
            this.modalConfirmacionAbierta = true;
            this.close();
       },
        confirmarEliminacionOferta(oferta) {
            this.ofertaActiva = oferta;
            this.modalConfirmacionEliminar = true;
            this.close();
        },
        abrirModal(oferta) {
            this.activeOferta = oferta;
            if (oferta.CMProducto.id_tipo_producto == 2) {
                this.modalDireccion = true;
                this.cargarDireccion(oferta.id);
            } else {
                this.modalRegion = true;
                this.cargarRegiones(oferta.id);
            }
        },
        async abrirModalGastosAdministrativos(oferta) {
            this.activeOferta = oferta;
            this.modalCombustible = true;
            this.listaGastosAdministrativos(oferta.id);
            this.listarSkuGastos();
        },
        abrirAgregar() {
            this.modalAgregar = true;
            this.obtenerProveedores();
            this.cargarUnidadesMedida();
        },
        async buscarProveedor(termino) {
            if (this.filtroProveedor === termino) return;
            this.filtroProveedor = termino;
            this.proveedor_list.data = [];
            this.paginacionProveedores.pagina = 1;
            this.obtenerProveedores();
        },
        async cargarMasProveedores() {
            if (this.proveedor_list.isLoading) return;
            const { page, per_page, total_rows } = this.proveedor_list.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionProveedores.pagina = page + 1;
            this.paginacionProveedores.registrosPorPagina = per_page;
            this.obtenerProveedores();
        },
        async cargarUnidadesMedida(){
            toggleLoadable(this.unidad_list)
            const { data } = await this.services.cmProducto.obtenerUnidadesMedida();
            setLoadableResponse(this.unidad_list, data,);
        },
        async obtenerProveedores() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionProveedores.registrosPorPagina,
                page: this.paginacionProveedores.pagina,
                busqueda: this.filtroProveedor,
            };

            const itemsCopy = this.proveedor_list.data;
            togglePageable(this.proveedor_list);
            const { data, headers} = await this.services.cmProducto.listProductoProveedor(this.producto.id, filtros);
            setPageableResponse(this.proveedor_list, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionProveedores;
                this.paginacionProveedores.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.proveedor_list.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        onSaveOffer(oferta) {
            this.$emit('on-save', oferta);
        },
        validacion() {
            let deshabilitar = false;
            this.precios.forEach(precio => {
                const manejoExistencia = precio.CMProducto.CmSubGrupo.manejo_existencias;
                if (!manejoExistencia) {
                    deshabilitar = true;
                }
            });
            return deshabilitar;
        },

        async cargarDireccion(ofertaId) {
           await this.cargarDirecciones(ofertaId);
        },
        async cargarRegion(ofertaId) {
           await this.cargarRegiones(ofertaId);
        },
        async listaGastosAdministrativos(ofertaId){
            const { data } = await this.services.cmProducto.listarConfiguracionGastosAdministrativos(ofertaId);
            this.listadoConfiguracionGastos = data;
            
        },
        async buscarSku(termino) {
            if (this.filtroSku === termino) return;
            this.filtroSku = termino;
            this.listaSku.data = [];
            this.paginacionSku.pagina = 1;
            this.listarSkuGastos();
        },
        async cargarMasSku() {
            if (this.listaSku.isLoading) return;
            const { page, per_page, total_rows } = this.listaSku.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionSku.pagina = page + 1;
            this.paginacionSku.registrosPorPagina = per_page;
            this.listarSkuGastos();
        },
        async listarSkuGastos() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionSku.registrosPorPagina,
                page: this.paginacionSku.pagina,
                busqueda: this.filtroSku,
            };

            const itemsCopy = this.listaSku.data;
            togglePageable(this.listaSku);
            const { data, headers} = await this.services.cmProducto.listaSkuGastosAdministrativos(filtros);
            setPageableResponse(this.listaSku, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionSku;
                this.paginacionSku.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.listaSku.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
    },
    computed: {
        items() {
            return this.$props.precios;
        },
    },
    created(){
        this.cargarUnidadesMedida();
    }
}
</script>