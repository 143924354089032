<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent fullscreen>
            <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
                <v-container style="position: relative;">
                    <div class="py-4"
                        style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                        <v-btn
                            style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                            class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </div>
                    <div>
                    <v-card-title class="d-flex align-center">
                        <v-icon class="mr-2">mdi mdi-store-plus-outline</v-icon>
                        <span class="font-weight-bold mr-2">Agregar oferta</span>
                    </v-card-title>

                        <v-form ref="form" class="mt-4">
                            <v-card style="overflow: hidden">
                                <v-col cols="12" md="8" offset-md="2">
                                    <v-row class="flex-column mx-0 my-0"
                                        style="text-align: center;gap: 10px;align-items: center;">
                                        <v-col cols="12" md="8" class="mt-4 px-0 py-0 flex-shrink-1 flex-grow-1">
                                            <ProgresiveLoadAutocomplete
                                                v-model="form.id_proveedor"
                                                placeholder="Seleccione un proveedor"
                                                label="Proveedor"
                                                outlined
                                                :items="proveedores.data"
                                                :loading="proveedores.isLoading"
                                                :pageable="proveedores" 
                                                item-value="id"
                                                item-text="nombre_comercial"
                                                :error-messages="proveedorErrors"
                                                @change="$v.form.id_proveedor.$touch()"
                                                @blur="$v.form.id_proveedor.$touch()"
                                                @search="buscarProveedor"
                                                @load-more="cargarMasProveedores"
                                                clearable
                                            />
                                        </v-col>
                                        <v-col cols="12" md="8" class="mb-5 px-0 py-0 flex-shrink-1 flex-grow-1">
                                            <v-autocomplete label="Unidad de medida" 
                                                :items="unidad.data" 
                                                item-text="nombre" 
                                                item-value="id"
                                                v-model="form.unidad_medida" 
                                                outlined 
                                                clearable hide-details 
                                                />
                                        </v-col>
                                        <v-col cols="12" md="8" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                                            <v-text-field 
                                                label="Precio"
                                                v-model="form.precio"
                                                type="number"
                                                :error-messages="precioErrors"
                                                @change="$v.form.precio.$touch()"
                                                @blur="$v.form.precio.$touch()"
                                                outlined />
                                        </v-col>
                                        <v-col cols="12" md="8" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                                            <v-text-field
                                                v-model="form.disponibilidad"
                                                label="Disponibilidad" 
                                                type="number"
                                                :error-messages="disponibilidadErrors"
                                                @change="$v.form.disponibilidad.$touch()"
                                                @blur="$v.form.disponibilidad.$touch()"
                                                outlined />
                                        </v-col>
                                        <v-col cols="12" md="8" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                                            <v-textarea
                                                label="Marca"
                                                outlined
                                                row-height="30"
                                                v-model="form.marca" 
                                                rows="4"
                                                auto-grow
                                                clearable
                                                counter="500"
                                                maxlength="500"
                                            />
                                        </v-col>
                                        <div class="d-flex justify-end mt-4">
                                            <v-btn class="flex-grow-1 flex-shrink-1" @click.stop="cerrarModal" color="secondary">Cerrar</v-btn>
                                            <v-btn class="flex-grow-1 flex-shrink-1 ml-4" @click=confirmarCrearOferta color="primary">Guardar</v-btn>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-form>
                    </div>
                </v-container>
            </div>
        </v-dialog>
        <ConfirmationModalComponent
            :isOpen="modalConfirmacionOferta" 
            description="¿Desea continuar con la acción?"
            @confirm="confirmarGuardado"
            @cancel="modalConfirmacionOferta = false"
            :isLoading="creacionOferta.isLoading"
        />
    </Fragment>
    
</template>
<script>
import { required, integer } from 'vuelidate/lib/validators'
import { RetryDataLoading, ConfirmationModalComponent, ProgresiveLoadAutocomplete } from '@/components/utils';
import { Validator } from '@/utils/form-validation.js';
import { Fragment } from 'vue-fragment';

import { 
    createLoadable, 
    toggleLoadable,
    setLoadableResponse,
    isResponseSuccesful,
} from '@/utils/loadable.js';

export default {
    name: 'CmOfertaModal',
    emits: ['on-save'],
    components: { RetryDataLoading, Fragment, ConfirmationModalComponent, ProgresiveLoadAutocomplete },
    props: {
        isOpen: { type: Boolean },
        producto: { type: Object },
        unidad: { type: Object },
        proveedores: { type: Object },
    },
    validations() {
        return {
            form: {
                id_proveedor: { required },
                precio: { required, },
                disponibilidad: { integer },
            },
        };
    },
    data: () => ({
        internalOpen: false,
        form: {
            id_producto: null,
            nombre_comercial: null,
            id_proveedor: null,
            precio: '',
            marca:'',
            disponibilidad: '',
            unidad_medida: null,
        },
        modalConfirmacionOferta: false,
        creacionOferta: createLoadable(null),
    }),
    computed: {
        proveedorErrors() {
            return new Validator(this.$v.form.id_proveedor).detect().getResult();
        },
        precioErrors() {
            return new Validator(this.$v.form.precio).detect().getResult();
        },
        disponibilidadErrors() {
            return new Validator(this.$v.form.disponibilidad).detect().getResult();
        },
    },
    methods: {
        async buscarProveedor(termino){
            this.$emit('buscarProveedor', termino)
        },        
        async cargarMasProveedores(){
            this.$emit('cargarMasProveedores')
        },      
        async obtenerUnidadesMedida(){
            this.$emit('cargarUnidadesMedida');
        },
        confirmarCrearOferta() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.modalConfirmacionOferta = true;
        },  
       async confirmarGuardado()
        {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            
            if (this.form.id_proveedor) {
                const proveedorSeleccionado = this.proveedores.data.find(provider => provider.id === this.form.id_proveedor);
                if (proveedorSeleccionado) {
                    this.form.nombre_comercial = proveedorSeleccionado.nombre_comercial;
                }
            }
            this.form.id_producto = this.producto.id;

            const params = {
                id_producto: this.form.id_producto,
                nombre_comercial: this.form.nombre_comercial,
                precio: this.form.precio,
                disponibilidad: this.form.disponibilidad,
                id_proveedor: this.form.id_proveedor,
                unidad_medida: this.form.unidad_medida,
                marca: this.form.marca,
            };  

            toggleLoadable(this.creacionOferta);
            const { data } = await this.services.cmProducto.crearOfertaProducto(params);
            setLoadableResponse(this.creacionOferta, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            this.modalConfirmacionOferta = false;
            this.limpiarFormulario() 
            this.cerrarModal();

            if (isResponseSuccesful(data)) {
                this.$emit('on-save', { id_producto: params.id_producto });
            }
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.form = {};
        },
        cerrarModal() {
            this.limpiarFormulario();
            this.$emit('close');
        },
    },
    created(){
    }
};
</script>